import { faker } from '@faker-js/faker'
import {
  SubscriptionStatus,
  SubscriptionType
} from '@local/manage-webhooks-shared'

const menuEventCategory = {
  guid: 'A124B563872432',
  name: 'Menus',
  eventSubjectType: 'menu'
}

const birthdayEventCategory = {
  guid: 'A124B563872438',
  name: 'Birthday',
  eventSubjectType: 'birthday'
}

const restaurantAvailabilityEventCategory = {
  guid: 'A124B563872416',
  name: 'Restaurant Availability',
  eventSubjectType: 'restaurant_availability'
}

const partnerEventCategory = {
  guid: 'A124B563872EF3',
  name: 'Partner',
  eventSubjectType: 'partner'
}

const stockEventCategory = {
  guid: 'A124B563872BAC',
  name: 'Stock',
  eventSubjectType: 'stock'
}

const eventCategories = [
  menuEventCategory,
  birthdayEventCategory,
  restaurantAvailabilityEventCategory,
  partnerEventCategory,
  stockEventCategory
]

const generateSubscriptionWithStatus = (status: SubscriptionStatus) => ({
  ...convertStatusToBools(status),
  guid: faker.datatype.uuid(),
  name: faker.company.name(),
  subscriberGuid: faker.datatype.uuid(),
  subscriberType: SubscriptionType.Partner,
  uri: faker.internet.url(),
  eventCategory: eventCategories[Math.floor(Math.random() * 5)]
})

const convertStatusToBools = (status: SubscriptionStatus) => {
  let stopped = false,
    paused = false
  switch (status) {
    case SubscriptionStatus.Paused:
      paused = true
      break
    case SubscriptionStatus.Inactive:
      stopped = true
      break
    default:
      break
  }

  return {
    paused,
    stopped
  }
}

export const generateNSubscriptionsWithStatus = (
  n: number,
  status: SubscriptionStatus
) => {
  return Array(n)
    .fill(null)
    .map(() => generateSubscriptionWithStatus(status))
}
