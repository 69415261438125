import { FunctionComponent } from 'react'
import { Badge, Colors } from '@toasttab/buffet-pui-badge'

import { SubscriptionStatus } from '../../types'

export interface StatusBadgeProps {
  status: SubscriptionStatus
}

const statusBadgeColorMap: {
  [status in SubscriptionStatus]: keyof typeof Colors
} = {
  [SubscriptionStatus.Paused]: Colors.info,
  [SubscriptionStatus.Inactive]: Colors.warning,
  [SubscriptionStatus.Active]: Colors.success
}

export const StatusBadge: FunctionComponent<StatusBadgeProps> = ({
  status
}) => {
  return (
    <Badge
      color={statusBadgeColorMap[status]}
      variant='statusLg'
      testId={`bage-${status}`}
    >
      {status}
    </Badge>
  )
}
