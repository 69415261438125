import { gql } from '@apollo/client'

export const schemaString = gql`
  input NewEventCategory {
    guid: String
    name: String!
    eventSubjectType: String!
  }

  input NewSubscription {
    guid: ID
    createdDate: String
    modifiedDate: String
    uri: String
    eventSubjectType: String
    subscriberType: String
    subscriberGuid: ID
    eventCategory: NewEventCategory
    secret: String
    createdBy: ID
    modifiedBy: ID
    stopped: Boolean
    paused: Boolean
    notificationEmail: String
    name: String
  }

  input GetEventsPageRequestInput {
    pageSize: Int
    lastEvaluatedKey: String
  }

  input GetEventsInput {
    pageRequest: GetEventsPageRequestInput!
    subscriptionGuid: String!
    startTime: String
    endTime: String
    status: String
    scanIndexForward: Boolean
  }

  type Webhook {
    getSubscriptions: [Subscription]
    getPartners: [Partner]
    getEventCategories: [EventCategory]
  }

  type Query {
    getSubscription(guid: ID!): Subscription
    getSubscriptionSecret(guid: ID!): Secret
    getSubscriptions: [Subscription]
    getEventCategories: [EventCategory]
    getEventCategory(guid: ID!): EventCategory
    getPartners: [Partner]
    resync: Boolean
    getEvents(getEventsInput: GetEventsInput): GetEventsResponse
    getWebhooks: [Webhook]
    getSubscriptionForSubscriber(guid: ID!): Subscription
  }

  type Mutation {
    createSubscription(
      subscription: NewSubscription
      userGuid: ID
    ): Subscription
    updateSubscription(
      subscription: NewSubscription
      userGuid: ID
    ): Subscription
    deleteSubscription(guid: ID, deletedBy: ID): Boolean
    deleteEventCategory(guid: ID!): Boolean
    updateEventCategory(eventCategory: NewEventCategory!): EventCategory
    createEventCategory(eventCategory: NewEventCategory!): EventCategory
    resendEvent(
      subscriptionGuid: ID
      webhookGuid: ID
      timestamp: String
    ): Boolean
  }

  type EventCategory {
    version: Int
    guid: ID
    name: String
    eventSubjectType: String
  }

  type GetEventsResponse {
    lastEvaluatedKey: String
    webhookEvents: [WebhookEvent]
  }

  type WebhookEvent {
    subscriptionGuid: ID
    timestamp: String
    messageBody: String
    webhookGuid: ID
    subscriptionUri: String
    signature: String
    eventCategory: String
    eventType: String
    eventGuid: ID
    routeKey: String
    responseCode: Int
    attempts: Int
    status: String
  }

  type Subscription {
    version: Int
    guid: ID
    createdDate: String
    modifiedDate: String
    uri: String
    eventSubjectType: String
    subscriberType: String
    subscriberGuid: ID
    eventCategory: EventCategory
    secret: String
    createdBy: ID
    modifiedBy: ID
    stopped: Boolean
    paused: Boolean
    notificationEmail: String
    name: String
  }

  type Secret {
    secret: String
  }

  type Partner {
    name: String
    guid: String
  }
`
