import { FunctionComponent, useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from '@toasttab/buffet-pui-modal'
import {
  TextInputField,
  CancelButton,
  SubmitButton
} from '@toasttab/buffet-pui-forms'
import { Form, Formik } from 'formik'

export interface ConfirmDeleteModalProps {
  isOpen: boolean
  onClose?: () => void
  onDelete?: () => Promise<any>
  isDeleting: boolean
}
const DELETE_KEY = 'DELETE'
export const ConfirmDeleteModal: FunctionComponent<ConfirmDeleteModalProps> = ({
  isOpen,
  onClose,
  onDelete,
  isDeleting
}) => {
  const [deleteInputText, setDeleteInputText] = useState('')
  const onTextTyped = (event: any) => {
    setDeleteInputText(event.target?.value)
  }

  const onDeletePress = () => {
    onDelete!().then(() => {
      onClose!()
    })
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      overflowBehavior='body'
      parentSelector={() => {
        const el = document.querySelector('#banquetPortalsContainer')!
        el?.setAttribute('data-wbhx', 'true')
        return el
      }}
      testId='confirm-delete-modal'
    >
      <Formik initialValues={{}} onSubmit={onDeletePress}>
        <Form>
          <ModalHeader>Are you sure?</ModalHeader>
          <ModalBody>
            <div className='flex flex-col space-y-4 mt-4 type-headline-5'>
              <p>Are you sure you wish to delete?</p>
              <p>This action cannot be reverted.</p>
              <p className='pt-4 w-full'>
                To continue, type{' '}
                <span className='font-bold'>{DELETE_KEY}</span> in all caps
                below.
              </p>
              <TextInputField
                containerClassName='lg:w-1/2 w-full'
                autoComplete='false'
                type='text'
                name='deleteInputText'
                value={deleteInputText}
                onChange={onTextTyped}
                aria-label='Confirm delete'
                data-testid='delete-input'
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <CancelButton onClick={onClose} data-testid='cancel-delete-button'>
              Cancel
            </CancelButton>
            <SubmitButton
              onClick={onDeletePress}
              disabled={deleteInputText !== DELETE_KEY || isDeleting}
              data-testid='confirm-delete-button'
            >
              Delete
            </SubmitButton>
          </ModalFooter>
        </Form>
      </Formik>
    </Modal>
  )
}
